<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">テキスト入力</p>
      <FormRow>
        <template v-slot:label :required="true">INPUT</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" placeholder="placeholder" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" disabled placeholder="disabled" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" readonly placeholder="readonly" />
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">テキスト入力</p>
      <FormRow>
        <template v-slot:label :required="true">INPUT</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" placeholder="placeholder" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" disabled placeholder="disabled" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" readonly placeholder="readonly" />
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section">
      <p class="form-headline">テキスト入力</p>
      <FormRow>
        <template v-slot:label :required="true">INPUT</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" placeholder="placeholder" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" disabled placeholder="disabled" />
          </div>
          <div class="form-content-row">
            <input class="form-control" type="text" name="" readonly placeholder="readonly" />
          </div>
        </template>
      </FormRow>
    </section>
  </div>
</template>

<script>
import FormRow from '@/components/FormRow.vue';
export default {
  data: function() {
    return {
      //   pageName: 'ユーザー',
    };
  },
  components: {
    FormRow,
  },
  mounted: function() {
    this.$emit('footer-show');
  },
};
</script>
